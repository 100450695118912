<template>
  <div
    class="v-stack h-stretch gap-3"
    v-if="project && $store.state.accessLevel > 4"
  >
    <div class="panel-right h-stretch">
      <div>Pricelists (Budget)</div>
    </div>

    <a class="heading-title-1">Finance</a>
    <template v-if="$store.state.accessLevel > 8">
      <div class="h-stack">
        <div class="h-stack gap-3">
          <button @click="showBudget()" class="submit">Budget</button>
          <button
            @click="$router.push('/projects/finance/' + id)"
            class="submit"
          >
            Details
          </button>
        </div>
      </div>

      <a class="heading-title-2">Overview</a>
      <div class="card light">
        <div class="content h-stretch">
          <div class="v-stack gap-3">
            <div class="collection">
              <div class="h-stack gap-1">
                <label>Budget:</label>
                <div>{{ format.currency(budget) }} Kč</div>
              </div>
              <div class="h-stack gap-1">
                <label>Expenses:</label>
                <div>{{ format.currency(workExpenses) }} Kč</div>
              </div>
              <div class="h-stack gap-1">
                <label>Additional Expenses:</label>
                <div>{{ format.currency(additionalExpenses) }} Kč</div>
              </div>
              <div class="h-stack gap-1">
                <label>Total Expenses:</label>
                <div>{{ format.currency(totalExpenses) }} Kč</div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer gap-3">
          <label>Balance:</label>
          <div v-bind:class="[balance >= 0 ? 'green' : 'red']">
            {{ format.currency(balance) }} Kč
          </div>
        </div>
      </div>
    </template>
    <a class="heading-title-2">Work hours</a>
    <div class="card light">
      <div class="content h-stretch">
        <div class="collection-3">
          <div
            class="card"
            v-for="expense in expensesEmployees.filter(
              (element) => element.cost > 0
            )"
            :key="expense.uuid"
          >
            <div class="header">
              <label class="capitalize">{{ expense.activity }}</label>
            </div>
            <div class="content h-stretch">
              <div class="v-stack gap-3 h-stretch">
                <div
                  class="h-stack h-space-between"
                  v-if="$store.state.accessLevel > 8"
                >
                  <label>Budget:</label>
                  <div>{{ format.currency(expense.budget) }} Kč</div>
                </div>
                <div
                  class="h-stack h-space-between"
                  v-if="$store.state.accessLevel > 8"
                >
                  <label>Cost:</label>
                  <div>{{ format.currency(expense.cost) }} Kč</div>
                </div>
                <div class="h-stack h-space-between">
                  <label>Hours:</label>
                  <div>
                    {{ Math.floor(expense.hours / 8) }}d
                    {{ expense.hours - Math.floor(expense.hours / 8) * 8 }}h /
                    {{ Math.floor(expense.budgetHours / 8) }}d
                    {{
                      expense.budgetHours -
                      Math.floor(expense.budgetHours / 8) * 8
                    }}h
                  </div>
                </div>
              </div>
            </div>
            <div
              class="footer h-space-between"
              v-if="$store.state.accessLevel > 8"
            >
              <label>Balance</label>
              <div
                v-bind:class="[
                  expense.budget - expense.cost >= 0 ? 'green' : 'red',
                ]"
              >
                {{ format.currency(expense.budget - expense.cost) }} Kč
              </div>
            </div>
          </div>
          <div
            v-if="
              expensesEmployees.filter((element) => element.cost > 0).length ==
              0
            "
            class="card"
          >
            <div>No data</div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="$store.state.accessLevel > 8">
      <a class="heading-title-2">Hired</a>
      <div class="card light">
        <div class="content gap-3">
          <label>Cost: </label>
          <div>{{ format.currency(expensesHired) }} Kč</div>
        </div>
      </div>
    </template>

    <a class="heading-title-2">Additional Expenses</a>
    <Table
      :items="project.expenses"
      :defaultColumn="0"
      :limit="5"
      css="1fr 80px 1fr"
      :expandable="true"
      v-on:rowSelected="$router.push('/projects/expenses/' + $event._id)"
      :columns="[
        {
          name: 'Title',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Cost',
          sort: 'numeric',
          path: ['amount'],
          formatType: 'currency',
          format: 'czk',
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['description'],
        },
      ]"
    >
    </Table>
    <div class="h-stack h-end">
      <button @click="$router.push('/projects/expenses/add/' + id)" class="add">
        Add
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import utils from "@/utils.js";
import constants from "@/constants.js";
import { v4 as uuidv4 } from "uuid";
import Table from "@/components/Table.vue";

export default {
  data() {
    return {
      preProductionRoles: [],
      productionRoles: [],
      postProductionRoles: [],
      project: null,
      expensesHired: 0,
      expensesEmployees: [],
      format: utils.format,
    };
  },
  props: ["id"],
  components: {
    Table,
  },
  computed: {
    budget() {
      let sum = 0;
      for (const pricelist of this.project.selectedPricelists) {
        sum += pricelist.totalPrice;
      }
      return sum;
    },
    balance() {
      return this.budget - this.totalExpenses;
    },
    workExpenses() {
      let value = 0;
      for (const expense of this.expensesEmployees) {
        value += expense.cost;
      }
      return value + this.expensesHired;
    },
    additionalExpenses() {
      let value = 0;

      for (const expense of this.project.expenses) {
        value += expense.amount;
      }

      return value;
    },
    totalExpenses() {
      return this.workExpenses + this.additionalExpenses;
    },
  },
  methods: {
    ...mapActions(["getProject", "addPricelist", "getPricelistItems"]),
    showBudget() {
      this.$router.push("/projects/pricelists/" + this.project._id);
    },
    refresh() {
      this.getProject(this.id)
        .then((project) => {
          this.project = project;

          // budget and pricelist total price with vat
          this.project.selectedPricelists = this.project.pricelists.filter(
            (pricelist) =>
              this.project.selectedPricelists.includes(pricelist._id)
          );

          for (const pricelist of this.project.selectedPricelists) {
            pricelist.totalPrice = utils.pricelist.totalPrice(pricelist);

            for (const item of pricelist.items) {
              for (const expense of this.expensesEmployees) {
                if (expense.activity == item.item.name) {
                  expense.budget += item.price * item.count;

                  for (const pricelistUnit of constants.pricelistUnits) {
                    if (pricelistUnit.name == item.item.unit) {
                      expense.budgetHours += pricelistUnit.value * item.count;
                    }
                  }
                }
              }
            }
          }

          // calculate price of activities
          for (const workhour of project.workhours) {
            for (const expense of this.expensesEmployees) {
              if (workhour.activity == expense.activity) {
                if (workhour.workday) {
                  if (workhour.workday.state == "signed") {
                    expense.hours += workhour.hours;
                    expense.cost += Math.round(
                      workhour.hours * workhour.pay.amount
                    );
                  }
                } else {
                  console.log("warning workhour doesnt have a workday");
                  console.log(workhour);
                }
              }
            }
          }

          // staff cost
          for (const staff of project.preproductionStaff) {
            this.expensesHired += staff.cost + staff.gearCost;
          }

          for (const day of project.recordingDays) {
            for (const staff of day.staff) {
              this.expensesHired += staff.cost + staff.gearCost;
            }
          }

          for (const staff of project.postproductionStaff) {
            this.expensesHired += staff.cost + staff.gearCost;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPricelistItems()
      .then((items) => {
        this.productionRoles = Array.from(
          new Set(
            items
              .filter((item) => item.categoryMain == "PERSONÁL / SLUŽBY")
              .filter((item) => item.categorySub == "Produkce")
              .map((item) => item.name)
          )
        );

        this.preProductionRoles = Array.from(
          new Set(
            items
              .filter((item) => item.categoryMain == "PERSONÁL / SLUŽBY")
              .filter((item) => item.categorySub == "Preprodukce")
              .map((item) => item.name)
          )
        );

        this.postProductionRoles = Array.from(
          new Set(
            items
              .filter((item) => item.categoryMain == "PERSONÁL / SLUŽBY")
              .filter((item) => item.categorySub == "Postprodukce")
              .map((item) => item.name)
          )
        );

        this.expensesEmployees = [
          ...new Set(
            this.preProductionRoles
              .concat(this.productionRoles)
              .concat(this.postProductionRoles)
          ),
        ].map((item) => {
          return {
            uuid: uuidv4(),
            activity: item,
            hours: 0,
            cost: 0,
            budget: 0,
            budgetHours: 0,
          };
        });

        this.refresh();
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.green {
  color: green;
}

.red {
  color: red;
}

.expenses-row {
  grid-auto-columns: 1fr 1fr 1fr !important;
  gap: 10px;
}

.capitalize {
  text-transform: capitalize;
}
</style>
