var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.project && _vm.$store.state.accessLevel > 4)?_c('div',{staticClass:"v-stack h-stretch gap-3"},[_vm._m(0),_c('a',{staticClass:"heading-title-1"},[_vm._v("Finance")]),(_vm.$store.state.accessLevel > 8)?[_c('div',{staticClass:"h-stack"},[_c('div',{staticClass:"h-stack gap-3"},[_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.showBudget()}}},[_vm._v("Budget")]),_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.$router.push('/projects/finance/' + _vm.id)}}},[_vm._v(" Details ")])])]),_c('a',{staticClass:"heading-title-2"},[_vm._v("Overview")]),_c('div',{staticClass:"card light"},[_c('div',{staticClass:"content h-stretch"},[_c('div',{staticClass:"v-stack gap-3"},[_c('div',{staticClass:"collection"},[_c('div',{staticClass:"h-stack gap-1"},[_c('label',[_vm._v("Budget:")]),_c('div',[_vm._v(_vm._s(_vm.format.currency(_vm.budget))+" Kč")])]),_c('div',{staticClass:"h-stack gap-1"},[_c('label',[_vm._v("Expenses:")]),_c('div',[_vm._v(_vm._s(_vm.format.currency(_vm.workExpenses))+" Kč")])]),_c('div',{staticClass:"h-stack gap-1"},[_c('label',[_vm._v("Additional Expenses:")]),_c('div',[_vm._v(_vm._s(_vm.format.currency(_vm.additionalExpenses))+" Kč")])]),_c('div',{staticClass:"h-stack gap-1"},[_c('label',[_vm._v("Total Expenses:")]),_c('div',[_vm._v(_vm._s(_vm.format.currency(_vm.totalExpenses))+" Kč")])])])])]),_c('div',{staticClass:"footer gap-3"},[_c('label',[_vm._v("Balance:")]),_c('div',{class:[_vm.balance >= 0 ? 'green' : 'red']},[_vm._v(" "+_vm._s(_vm.format.currency(_vm.balance))+" Kč ")])])])]:_vm._e(),_c('a',{staticClass:"heading-title-2"},[_vm._v("Work hours")]),_c('div',{staticClass:"card light"},[_c('div',{staticClass:"content h-stretch"},[_c('div',{staticClass:"collection-3"},[_vm._l((_vm.expensesEmployees.filter(
            function (element) { return element.cost > 0; }
          )),function(expense){return _c('div',{key:expense.uuid,staticClass:"card"},[_c('div',{staticClass:"header"},[_c('label',{staticClass:"capitalize"},[_vm._v(_vm._s(expense.activity))])]),_c('div',{staticClass:"content h-stretch"},[_c('div',{staticClass:"v-stack gap-3 h-stretch"},[(_vm.$store.state.accessLevel > 8)?_c('div',{staticClass:"h-stack h-space-between"},[_c('label',[_vm._v("Budget:")]),_c('div',[_vm._v(_vm._s(_vm.format.currency(expense.budget))+" Kč")])]):_vm._e(),(_vm.$store.state.accessLevel > 8)?_c('div',{staticClass:"h-stack h-space-between"},[_c('label',[_vm._v("Cost:")]),_c('div',[_vm._v(_vm._s(_vm.format.currency(expense.cost))+" Kč")])]):_vm._e(),_c('div',{staticClass:"h-stack h-space-between"},[_c('label',[_vm._v("Hours:")]),_c('div',[_vm._v(" "+_vm._s(Math.floor(expense.hours / 8))+"d "+_vm._s(expense.hours - Math.floor(expense.hours / 8) * 8)+"h / "+_vm._s(Math.floor(expense.budgetHours / 8))+"d "+_vm._s(expense.budgetHours - Math.floor(expense.budgetHours / 8) * 8)+"h ")])])])]),(_vm.$store.state.accessLevel > 8)?_c('div',{staticClass:"footer h-space-between"},[_c('label',[_vm._v("Balance")]),_c('div',{class:[
                expense.budget - expense.cost >= 0 ? 'green' : 'red' ]},[_vm._v(" "+_vm._s(_vm.format.currency(expense.budget - expense.cost))+" Kč ")])]):_vm._e()])}),(
            _vm.expensesEmployees.filter(function (element) { return element.cost > 0; }).length ==
            0
          )?_c('div',{staticClass:"card"},[_c('div',[_vm._v("No data")])]):_vm._e()],2)])]),(_vm.$store.state.accessLevel > 8)?[_c('a',{staticClass:"heading-title-2"},[_vm._v("Hired")]),_c('div',{staticClass:"card light"},[_c('div',{staticClass:"content gap-3"},[_c('label',[_vm._v("Cost: ")]),_c('div',[_vm._v(_vm._s(_vm.format.currency(_vm.expensesHired))+" Kč")])])])]:_vm._e(),_c('a',{staticClass:"heading-title-2"},[_vm._v("Additional Expenses")]),_c('Table',{attrs:{"items":_vm.project.expenses,"defaultColumn":0,"limit":5,"css":"1fr 80px 1fr","expandable":true,"columns":[
      {
        name: 'Title',
        sort: 'alphabet',
        path: ['name'],
      },
      {
        name: 'Cost',
        sort: 'numeric',
        path: ['amount'],
        formatType: 'currency',
        format: 'czk',
      },
      {
        name: 'Description',
        sort: 'alphabet',
        path: ['description'],
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/projects/expenses/' + $event._id)}}}),_c('div',{staticClass:"h-stack h-end"},[_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$router.push('/projects/expenses/add/' + _vm.id)}}},[_vm._v(" Add ")])])],2):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel-right h-stretch"},[_c('div',[_vm._v("Pricelists (Budget)")])])}]

export { render, staticRenderFns }